import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategyService extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const isLibrary = routerState.url.includes('/library');
    const module = isLibrary ? 'Library' : 'Room';
    let title = this.buildTitle(routerState);

    // Library custom generated titles
    if (isLibrary) {
      let lastChild = routerState.root;
      let maxIterations = 10;
      const children = [lastChild];
      while (lastChild.firstChild && maxIterations > 0) {
        lastChild = lastChild.firstChild;
        children.push(lastChild);
        maxIterations--;
      }

      const rawTitles = children.map(c => c.title).filter(t => !!t);
      const uniqTitles = new Set(rawTitles);
      const titles = [...uniqTitles];

      if (titles.length > 0) {
        title = titles.pop();
      }
    }
    const titleSuffix = `${module} - Presence`;
    const fullTitle = title ? `${title} - ${titleSuffix}` : titleSuffix;
    this.title.setTitle(fullTitle);
  }
}
