import { Injectable } from '@angular/core';

export enum LocalStorageKey {
  // PL prefixed
  API_DEBUG = 'PL_API_DEBUG',
  BROWSER_ID = 'pl-browser-id',
  INACTIVE = 'plInactive',
  JUMBOTRON_CLIENT = 'pl-jumbotron-client',
  JUMBOTRON_ID = 'pl-jumbotron-id',
  LAST_REST_COUNT = 'PL_LAST_REST_COUNT',
  LAST_REST_TIME = 'PL_LAST_REST_TIME',
  LOGIN_TIME = 'pl-login-time',
  PL_URLS = 'plUrlsUrls',
  SKIN_TONE = 'plSkintone',
  SUPPRESS_502 = 'PL_SUPPRESS_502',
  TABLE_VIEW_PREFS = 'pl-table-view-preference',
  TEAM_WRITE_SHOW_DOCS = 'pl-team-write-show-documents',
  TEAM_WRITE_SHOW_TRASHED_DOCS = 'pl-team-write-show-trashed-documents',
  TEST_KIT_BANNER = 'pl-test-kit-banner',

  // Lightyear prefixed
  ACTIVE_QUEUE_ID = 'lightyear.activeQueueId',
  DICTATION = 'lightyear.documentationNotesDictation',
  MEMORY_XRAY = 'lightyear.memorycards:x-ray',
  USERNAME = 'lightyear.username',
  USER_GUID = 'lightyear.user_guid',

  // Other
  DEBUG_BLACKOUT_DAY = 'DEBUG_BLACKOUT_DAY',
  DEVICES = 'devices',
  DOC_CLIENTS = 'documentationClients',
  DOC_ITEM_STATES = 'documentationItemStates',
  INTRO_JS = 'introjs',
  KANGA_DOCS = 'kangaDocumentation',
  NEW_GAME_OVERRIDE = 'DEV_RVG_NEW_GAME_OVERRIDE',
  PROXY = 'firebase_force_proxy',
  TECH_CHECK_DEVICE_ID = 'techcheckDeviceId',
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  set(key: LocalStorageKey | string, value: any) {
    const val = this.toJson(value);
    localStorage.setItem(key, val);
  }

  get(key: LocalStorageKey | string) {
    const item = localStorage.getItem(key);
    return this.fromJson(item);
  }

  remove(key: LocalStorageKey | string) {
    localStorage.removeItem(key);
  }

  private fromJson(json: string) {
    if (!json || json === 'null') {
      return null;
    }

    try {
      return JSON.parse(json);
    } catch (e) {
      console.debug(e);
      return json;
    }
  }

  private toJson(obj: any) {
    if (typeof obj === 'undefined') {
      return null;
    }
    return JSON.stringify(obj);
  }
}
