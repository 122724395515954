export enum FeatureFlag {
  BOOMCARDS_SITESHARE = 'boomcardsSiteShare',
  BOOMCARDS_IFRAME = 'boomcardsIframe',
  INTEGRATED_LIBRARY = 'integratedLibrary',
  INACTIVITY_AUTOLOGOUT_BYPASS = 'inactivityAutologoutBypass',
  VIEW_AI_SUMMARY = 'viewAISummary',
  WJV_ASSESSMENTS = 'wjvAssessments',
  NEW_PARTICIPANTS_PANEL = 'newParticipantsPanel',
  CLIENT_MOOD_PROMPT = 'clientMoodPrompt',
  PRACTICE_TEST_KIT_BANNER = 'practiceTestKitBanner',
}
