import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store';
import { selectIsHijackedUser } from './modules/user/store';
import { NavigationSkipped, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PageTitleStrategyService } from '@app/page-title-strategy.service';

@Component({
  selector: 'pl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnDestroy, OnInit {
  isHijacked$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  constructor(
    store: Store<AppState>,
    private router: Router,
    private titleStrategyService: PageTitleStrategyService,
  ) {
    this.isHijacked$ = store.select(selectIsHijackedUser);
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationSkipped) {
        this.titleStrategyService.updateTitle(this.router.routerState.snapshot);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onActivate() {
    const loader = document.querySelector('#page-loader');
    if (loader) {
      loader.remove();
    }
  }
}
